var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{
    'bb-block-radio-heading': true,
    'pt-4': true,
    'bb-block-radio-heading--mobile': _vm.$voicer.isMobile,
    'pa-0': _vm.$voicer.isMobile,
  },style:({
    backgroundColor: _vm.$voicer.getShellConfig('featuredSectionColor'),
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"bb-block-radio-heading__wrapper"},[_c('div',{staticClass:"bb-block-radio-heading-block",style:({
        backgroundImage: ("url(" + (require('~/excentrics/shells/bb-multiradios/assets/megaphone.png')) + ")"),
      })},[_c('div',{staticClass:"bb-block-radio-heading-block-container"},[_c('div',{staticClass:"bb-block-radio-heading-block-container__text"},[_c('div',{staticClass:"bb-block-radio-heading-block-container__text__wrapper"},[_c('h2',{staticClass:"title"},[_c('strong',{style:({
                  color: _vm.$voicer.getShellConfig('featuredSectionColor'),
                })},[_vm._v("LÂCHE TA")]),_vm._v(" "),_c('span',{staticClass:"primary--text"},[_vm._v("DEDICACE")])]),_vm._v(" "),_c('h3',{staticClass:"body-2"},[_vm._v("ET PASSE A LA RADIO")])])]),_vm._v(" "),_c('div',{staticClass:"bb-block-radio-heading-block-container__actions"},[_c('v-btn',{attrs:{"color":"primary","fab":"","href":("" + (_vm.$voicer.getShellConfig('radioHeading.mail')))}},[_c('v-icon',[_vm._v("mail")])],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","fab":"","href":("" + (_vm.$voicer.getShellConfig('radioHeading.phone'))),"title":_vm.$voicer.getShellConfig('radioHeading.phoneText')}},[_c('v-icon',[_vm._v("phone")])],1)],1)])])]),_vm._v(" "),_c('div',{staticClass:"block-assistance"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("build")]),_vm._v("\n    Votre poste radio est en panne/cassé ? Envoyez un mail à\n    "),_c('a',{attrs:{"href":"mailto:sav.carglass@mediameeting.fr"}},[_vm._v("sav.carglass@mediameeting.fr")]),_vm._v("\n    ou appelez le\n    "),_c('a',{attrs:{"href":"tel:+33186861944"}},[_vm._v("01 86 86 19 44")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }