<template>
  <div
    class="bb-card-holder fill-height"
    :class="{
      'pa-4': $voicer.isMobile === false,
      'pa-2': $voicer.isMobile === true,
    }"
  >
    <v-card
      ripple
      :color="getColor"
      dark
      flat
      :class="{
        'bb-card': true,
        'bb-card--selected': isSelectedContent,
        'bb-card--ie': $voicer.isIE,
      }"
      @click.stop="handleSelectWavy"
    >
      <v-switch
        value
        :input-value="isSelectedContent"
        color="primary"
      ></v-switch>
      <div class="bb-card__title title">
        {{ title }}
      </div>
    </v-card>
  </div>
</template>

<script>
import contentInfos from '~/mixins/contentInfos'
import mediaMixin from '~/mixins/media'

export default {
  components: {},
  mixins: [contentInfos, mediaMixin],
  props: {
    imageProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    cardStyle() {
      return {
        backgroundImage: `url(${this.cardImg.originSrc})`,
      }
    },
    getColor() {
      return this.isSelectedContent ? '#333' : '#121212'
    },
  },
}
</script>

<style lang="scss">
.bb-card {
  background: #272727;
  border-radius: 4px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  min-height: 28px;

  &--holder {
    height: 100%;
  }

  &__title {
    padding-left: 10px;
  }

  .v-input {
    position: absolute;
    top: 8px;
    left: 5px;
  }

  &--ie {
    .v-input {
      top: 2px;
    }
  }

  .v-input--selection-controls {
    margin: 0 !important;
    padding: 0 !important;
  }

  .v-input__slot {
    margin-bottom: 0px !important;
  }

  img {
    max-width: 100%;
  }

  &__picture {
    background-size: contain;
    background-position: center;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    cursor: pointer;
    position: relative;
  }

  &__metas {
    text-align: left;
  }

  .bb-loader-indicator {
    position: absolute;
    top: 12px;
    left: 38px;
  }
}
</style>
