var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"750","max-width":"100%","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"mx-auto",class:{
      'w-carglass-form-modal': true,
      'w-carglass-form-modal--mobile': _vm.$voicer.isMobile,
    }},[_c('v-toolbar',{staticClass:"color: #fbde51",attrs:{"flat":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.open = false}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("close")])],1)],1),_vm._v(" "),_c('v-img',{attrs:{"src":_vm.$voicer.isMobile
          ? require('~/assets/images/carglass.png')
          : require('~/assets/images/carglass.png'),"alt":"image emission","max-height":_vm.$voicer.isMobile === false ? 320 : undefined}}),_vm._v(" "),_c('v-card-text',{staticClass:"w-carglass-form-modal__body2",class:{
        'pa-2 subtitle-2': _vm.$voicer.isMobile === false,
        'pa-1 subtitle-2': _vm.$voicer.isMobile === true,
      }},[_c('div',{staticClass:"body-2"},[_vm._v("Prochaine émission Carglass Radio Live")]),_vm._v(" "),_c('div',[_vm._v("Mardi 6 juin 2023 à 12h")]),_vm._v(" "),_c('div',{staticClass:"body-2"},[_vm._v("\n        Les membres du Comité de Direction répondent en direct à vos questions\n        ! Envoyez-les dès maintenant à\n\n        "),_c('a',{attrs:{"href":"mailto:radio@carglass.fr"}},[_vm._v("radio@carglass.fr")]),_vm._v(" ou au\n        "),_c('a',{attrs:{"href":"tel:+33186860087"}},[_vm._v("01 86 86 00 87")])]),_vm._v(" "),_c('div',{staticClass:"body-2"},[_vm._v("\n        Ou de façon "),_c('strong',[_vm._v("anonyme")]),_vm._v(" au lien\n        "),_c('a',{attrs:{"href":"https://app.sli.do/event/eq26NbmhjURixsd9F4rT4F/live/questions"}},[_vm._v("\n          ici\n        ")])]),_vm._v(" "),_c('div',{staticClass:"image"},[_c('img',{staticClass:"pt-2",attrs:{"src":require('~/assets/images/qrcode17.PNG'),"height":"75","alt":"qrcode"}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }