<i18n>
  {
    "en": {
      "connect_to": "Connect to"
    },
    "fr": {
      "connect_to": "Connectez-vous pour accéder à"
    }
  }
</i18n>

<template>
  <w-layout-modal
    :class="{
      'w-page-login--mobile': $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    class="w-page-login"
  >
    <template v-slot:content>
      <WAuthenticator
        :auth-providers="authProviders"
        :can-use-local-registration="canUseLocalRegistration"
        :can-use-local-signin="authLocal"
        :local-signin-options="localSigninOptions"
        @connectprovider="handleSelectProvider"
        @connectproviderreject="handleProviderRejectConnection"
        @connectprovidersuccess="handleProviderSuccessConnection"
        @register="handleRegister"
        @connect="handleConnect"
        @connectsuccess="handleConnectSuccess"
        @connectreject="handleConnectReject"
      >
        <template v-slot:header>
          {{ $t('connect_to') }}<br /><strong>{{ $metadatas.appName }}</strong>
        </template>
      </WAuthenticator>
    </template>
    <template v-slot:baseline>
      <nuxt-link to="/data/terms-of-service">
        {{ $t('terms_usage_policy') }}
      </nuxt-link>
      <nuxt-link to="/data/legal-notice">
        {{ $t('legal_notice') }}
      </nuxt-link>
      <nuxt-link to="/data/personal-data">
        {{ $t('confidentiality_policy') }}
      </nuxt-link>
    </template>
  </w-layout-modal>
</template>

<script>
import { mapState } from 'vuex'
import {
  getConfig,
  getLocalAuthOptions,
  getSSOProviders,
  useLocalAuth,
} from '~/utils/voicer'

import WAuthenticator from '~/components/WAuthenticator'

export default {
  name: 'PageLogin',
  layout: 'minimal',

  components: {
    WAuthenticator,
  },

  data: () => ({
    isLoading: false,
  }),

  computed: {
    authOptInRequired() {
      return getConfig('authOptInRequired')
    },
    localSigninOptions() {
      return getLocalAuthOptions()
    },
    ...mapState({
      authLocal: (state) => {
        return useLocalAuth()
      },
      authProviders: (state) => getSSOProviders(),
      canUseLocalRegistration(state) {
        return (
          state.root.site.optRegistrable === true &&
          this.localSigninOptions.mode === 'default'
        )
      },
      isAuthenticated: (state) => state.auth.isAuthenticated,
      isAuthLoading: (state) => state.auth.isLoading,
    }),
  },

  methods: {
    handleRegister() {
      this.$router.push('/register')
    },
    handleSelectProvider(provider) {
      this.isLoading = true
    },
    handleProviderRejectConnection(e) {
      this.isLoading = false
    },
    handleProviderSuccessConnection(e) {
      this.isLoading = false
    },
    handleConnect() {
      this.isLoading = true
    },
    handleConnectReject() {
      this.isLoading = false
    },
    handleConnectSuccess() {
      this.isLoading = false
    },
  },

  head() {
    return {
      title: this.$voicer.headTitle(this.$route, this.$store.state),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$voicer.headDescription(this.$route, this.$store.state),
        },
      ],
    }
  },
}
</script>
