<i18n>
{
  "en": {
    "create_account": "Create account",
    "next": "Next",
    "login": "Login",
    "or": "OR",
    "fail": "I do not manage to login",
    "inform_cgu_before_connection": "By logging in, you accept the general conditions of use"
  },
  "fr": {
    "create_account": "Créer un compte",
    "next": "Continuer",
    "login": "Connexion",
    "or": "OU",
    "fail": "Je n'arrive pas à me connecter",
    "inform_cgu_before_connection": "En vous connectant, vous acceptez les conditions générales d'utilisation"
  }
}
</i18n>

<template>
  <div class="w-authenticator">
    <div class="w-authenticator__header">
      <slot name="header" />
    </div>
    <div class="w-authenticator__content">
      <v-form
        v-if="canUseLocalSignin"
        ref="form"
        lazy-validation
        @submit.prevent="handleLoginSubmit"
      >
        <v-window v-model="stepLogin">
          <v-window-item v-if="isDefaultMode" :value="1">
            <WFormInputLogin ref="login" v-model="loginValue" />
          </v-window-item>
          <v-window-item :value="2">
            <div class="body-1 mb-1" style="margin-left: 36px; height: 19px">
              <div v-show="isDefaultMode && loginValue !== ''">
                <a href="#" @click.prevent="handleResetLogin"
                  >Vous n'êtes pas<br /><strong>{{ loginValue }}</strong> ?</a
                >
              </div>
            </div>
            <WFormInputPassword
              ref="password"
              v-model="password"
              :props="compPasswordDynamicProps"
              @click:prepend="() => (stepLogin = 1)"
            />
          </v-window-item>
        </v-window>
        <v-card-actions
          class="w-authenticator__actions w-authenticator__terms-express body-1"
        >
          <nuxt-link to="/data/terms-of-service">
            {{ $t('inform_cgu_before_connection') }}
          </nuxt-link>
        </v-card-actions>
        <v-card-actions class="w-authenticator__actions">
          <div
            v-if="isDefaultMode && stepLogin === 1"
            :class="{
              ['w-authenticator__actions__first--solo']:
                canUseLocalRegistration === false,
            }"
            class="w-authenticator__actions__first"
          >
            <v-btn
              v-if="canUseLocalRegistration"
              flat
              color="primary"
              @click.prevent="$router.push('/register')"
            >
              {{ $t('create_account') }}
            </v-btn>
            <v-btn
              :disabled="!loginValue"
              :block="canUseLocalRegistration === false"
              color="primary"
              depressed
              type="submit"
              @click.prevent="handleNextStep"
            >
              {{ $t('next') }}
            </v-btn>
          </div>
          <v-btn
            v-if="isUniquePasswordMode || stepLogin === 2"
            :disabled="!loginValue"
            color="primary"
            block
            depressed
            type="submit"
          >
            {{ $t('login') }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-card-actions
        v-if="authProviders.length > 0"
        class="w-authenticator__actions w-authenticator__providers"
      >
        <div v-if="canUseLocalSignin" class="body-2 grey--text text--darken-1">
          {{ $t('or') }}
        </div>
        <div class="w-authenticator__providers__list">
          <WAuthProviders
            :providers="authProviders"
            @reject="(e) => $emit('connectproviderreject', e)"
            @select="(provider) => $emit('connectprovider', provider)"
            @success="(data) => $emit('connectprovidersuccess', data)"
          />
        </div>
      </v-card-actions>
      <v-card-actions
        v-if="displayResetPassword"
        class="w-authenticator__actions w-authenticator__more"
        :class="{ 'w-authenticator__more--mobile': $voicer.isMobile }"
      >
        <v-btn
          nuxt
          :to="'/password_reset?lv=' + loginValue"
          flat
          :block="$voicer.isMobile"
        >
          {{ $t('fail') }}
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import WAuthProviders from './WAuthProviders'
import WFormInputLogin from '~/components/WForm/WInputLogin'
import WFormInputPassword from '~/components/WForm/WInputPassword'

export default {
  components: {
    WAuthProviders,
    WFormInputLogin,
    WFormInputPassword,
  },
  props: {
    authProviders: {
      type: Array,
      required: true,
    },
    canUseLocalRegistration: {
      type: Boolean,
      default: false,
    },
    canUseLocalSignin: {
      type: Boolean,
      default: false,
    },
    localSigninOptions: {
      type: Object,
      default: () => ({
        mode: 'default',
      }),
    },
  },
  data() {
    let stepLogin = 1
    let loginValue = ''

    // computed properties are not computed here
    // see vue lifecycle
    if (this.localSigninOptions.mode === 'unique_password') {
      stepLogin = 2
      loginValue = this.localSigninOptions.defaultLogin
    }

    return {
      stepLogin,
      loginValue,
      password: '',
    }
  },
  computed: {
    isDefaultMode() {
      return this.localSigninOptions.mode === 'default'
    },
    isUniquePasswordMode() {
      return this.localSigninOptions.mode === 'unique_password'
    },
    hasSSOProviders() {
      return this.authProviders.length > 0
    },
    compPasswordDynamicProps() {
      if (this.isDefaultMode) {
        return {
          'prepend-icon': 'arrow_back',
        }
      }
      return null
    },
    displayResetPassword() {
      return (
        this.$voicer.enabledComponent('wAuthenticatorPasswordReset') &&
        this.isDefaultMode
      )
    },
  },
  mounted() {
    if (this.$refs.login && this.$refs.login.value) {
      this.loginValue = this.$refs.login.value
    }
  },
  methods: {
    ...mapActions({
      authenticate: 'auth/authenticate',
    }),

    handleLoginSubmit() {
      let loginValue

      if (this.isDefaultMode) {
        loginValue = this.loginValue
      } else {
        loginValue = this.localSigninOptions.defaultLogin
      }

      console.log('currentstep', this.stepLogin)

      const payload = {
        login: loginValue,
        password: this.password,
      }

      this.$emit('connect', payload)
      return this.authenticate(payload)
        .then(() => {
          this.$emit('connectsuccess')
        })
        .catch((e) => {
          this.stepLogin = 1
          this.$emit('connectreject', e)
        })
    },

    handleNextStep() {
      this.stepLogin += 1
      // yepa...
      // $nextTick is not suffisant
      setTimeout(() => {
        this.$refs.password.$refs.password.$refs.input.focus()
      }, 100)
    },

    handleResetLogin() {
      this.loginValue = ''
      this.stepLogin = 1
    },
  },
}
</script>

<style lang="scss">
.w-authenticator .v-window__container .v-window-item > div {
  height: auto !important;
}
</style>

<style lang="scss" scoped>
.w-authenticator {
  &__header {
    padding-bottom: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__providers {
    display: flex;
    flex-direction: column;

    &__list {
      margin-top: 12px;
    }
  }

  &__terms-express {
    text-align: center;
  }

  &__actions {
    width: 280px;
    margin: 0 auto;

    &__first {
      display: flex;
      justify-content: space-between;

      &--solo {
        width: 100%;
      }
    }
  }

  &__more {
    width: auto;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--mobile {
      justify-content: flex-end;
    }
  }
}
</style>
