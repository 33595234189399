<template>
  <client-only>
    <WDesktopLive
      v-if="$vuetify.breakpoint.smAndUp && isLive"
      :content="content"
      :episode="episode"
      v-bind="$attrs"
    />
    <WDesktop
      v-else-if="$vuetify.breakpoint.smAndUp"
      :content="content"
      :episode="episode"
      v-bind="$attrs"
    />
    <WMobile v-else :content="content" :episode="episode" v-bind="$attrs" />
  </client-only>
</template>

<script>
import { mapGetters } from 'vuex'

import WMobile from './WMobile'
import WDesktop from './WDesktop'
import WDesktopLive from './WDesktopLive'
import { $data } from '~/utils/spoke'

export default {
  components: {
    WDesktop,
    WDesktopLive,
    WMobile,
  },
  computed: {
    ...mapGetters({
      content: 'player/content',
      episode: 'player/currentEpisode',
    }),
    isLive() {
      return $data(this.content, 'type') === 'live'
    },
  },
}
</script>
