<template>
  <v-dialog v-model="open" width="750" max-width="100%" persistent scrollable>
    <v-card
      class="mx-auto"
      :class="{
        'w-carglass-form-modal': true,
        'w-carglass-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="color: #fbde51">
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="black">close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        :src="
          $voicer.isMobile
            ? require('~/assets/images/carglass.png')
            : require('~/assets/images/carglass.png')
        "
        alt="image emission"
        :max-height="$voicer.isMobile === false ? 320 : undefined"
      />
      <v-card-text
        class="w-carglass-form-modal__body2"
        :class="{
          'pa-2 subtitle-2': $voicer.isMobile === false,
          'pa-1 subtitle-2': $voicer.isMobile === true,
        }"
      >
        <div class="body-2">Prochaine émission Carglass Radio Live</div>
        <div>Mardi 6 juin 2023 à 12h</div>
        <div class="body-2">
          Les membres du Comité de Direction répondent en direct à vos questions
          ! Envoyez-les dès maintenant à

          <a href="mailto:radio@carglass.fr">radio@carglass.fr</a> ou au
          <a href="tel:+33186860087">01 86 86 00 87</a>
        </div>
        <div class="body-2">
          Ou de façon <strong>anonyme</strong> au lien
          <a
            href="https://app.sli.do/event/eq26NbmhjURixsd9F4rT4F/live/questions"
          >
            ici
          </a>
        </div>
        <div class="image">
          <img
            :src="require('~/assets/images/qrcode17.PNG')"
            height="75"
            alt="qrcode"
            class="pt-2"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import localstore from 'store'
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open') || null
    let open = false
    if (!lastOpen) open = true
    else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open === true) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
    }
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleClick() {
      this.open = false
      this.$emit('open-form')
    },
  },
}
</script>

<style lang="scss">
.w-carglass-form-modal {
  &--mobile {
    display: flex;
    flex-direction: column;
    .v-card__text {
      flex-grow: 1;
      position: relative;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__body2 {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .body-2 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .image {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}
</style>
