<i18n>
  {
    "en": {
      "account_create": "Create an account",
      "access": "Access to {to}",
      "subscribe": "Register to access to",
      "already_account": "I already have an account"
    },
    "fr": {
      "account_create": "Créer un compte",
      "access": "Accéder à {to}",
      "subscribe": "Inscrivez-vous pour accéder à",
      "already_account": "J'ai déjà un compte"
    }
  }
</i18n>

<template>
  <w-layout-modal
    :loading="isLoading"
    :class="{
      'w-page-register--mobile': $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    class="w-page-register"
    size="large"
  >
    <template v-slot:content>
      <div class="w-page-register__heading pa-2">
        <div class="w-page-register__heading__tile">
          <h2 class="title">
            {{ $t('account_create') }}
          </h2>
          <h3 class="body-2 mt-2">
            {{ $t('access', { to: $metadatas.appName }) }}
          </h3>
        </div>
        <div class="w-page-register__heading__actions">
          <v-btn flat small color="grey" @click="$router.push('/login')">
            {{ $t('already_account') }}
          </v-btn>
        </div>
      </div>
      <WRegister
        :auth-providers="authProviders"
        @connectprovider="handleSelectProvider"
        @connectproviderreject="handleProviderRejectConnection"
        @connectprovidersuccess="handleProviderSuccessConnection"
        @register="handleRegister"
        @registersuccess="handleRegisterSuccess"
        @registerreject="handleRegisterReject"
        @connect="handleConnect"
        @connectsuccess="handleConnectSuccess"
        @connectreject="handleConnectReject"
      >
        <template v-slot:header>
          {{ $t('subscribe') }}<br /><strong>{{ $metadatas.appName }}</strong>
        </template>
      </WRegister>
    </template>
    <template v-slot:baseline>
      <nuxt-link to="/data/terms-of-service">
        {{ $t('terms_usage_policy') }}
      </nuxt-link>
      <nuxt-link to="/data/legal-notice">
        {{ $t('legal_notice') }}
      </nuxt-link>
      <nuxt-link to="/data/personal-data">
        {{ $t('confidentiality_policy') }}
      </nuxt-link>
    </template>
  </w-layout-modal>
</template>

<script>
import { mapState } from 'vuex'
import { getConfig, getSSOProviders, useLocalAuth } from '~/utils/voicer'

import WRegister from '~/components/WRegister'

export default {
  layout: 'minimal',

  name: 'Register',

  components: {
    WRegister,
  },

  data: () => ({
    isLoading: false,
  }),

  computed: {
    authOptInRequired() {
      return getConfig('authOptInRequired')
    },
    logoPath() {
      return getConfig('metadatas.iconFileName')
    },
    ...mapState({
      authLocal: (state) => {
        return useLocalAuth()
      },
      authProviders: (state) => getSSOProviders(),
      canRegister: (state) => state.root.site.optRegister === true,
      isAuthenticated: (state) => state.auth.isAuthenticated,
    }),
  },

  methods: {
    handleRegister() {
      this.$router.push('/register')
    },
    handleRegisterSuccess() {
      this.$router.push({
        path: '/register/activate',
        query: {
          uid: this.$spoke.user.id,
        },
      })
    },
    handleRegisterReject() {
      this.isLoading = false
    },
    handleSelectProvider(provider) {
      this.isLoading = true
    },
    handleProviderRejectConnection(e) {
      this.isLoading = false
    },
    handleProviderSuccessConnection(e) {
      this.isLoading = false
    },
    handleConnect() {
      this.isLoading = true
    },
    handleConnectReject() {
      this.isLoading = false
    },
    handleConnectSuccess() {
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
.w-page-register__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
