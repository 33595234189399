<i18n>
{
  "en": {
    "live": "Live",
    "first_episode": "Episode 1"
  },
  "fr": {
    "live": "Direct",
    "first_episode": "Épisode 1"
  }
}
</i18n>

<template>
  <transition name="slide">
    <div
      v-if="isActive"
      :class="{
        'w-player-persistent': true,
        'w-player-persistent--with-bottom-nav': hasComponentBottomNav,
      }"
      :v-ripple="true"
    >
      <WPlayerProgress :is-live="isLive" slider />
      <v-card
        class="w-player-persistent__card"
        :color="$voicer.getThemeConfigProperty('player.backgroundColor')"
        tile
        flat
      >
        <div class="w-player-persistent__card__controls">
          <span v-if="isLive" class="text-is-live red white--text caption">{{
            $t('live')
          }}</span>
          <WButtonIndex
            v-if="!isLive"
            class="hidden-xs-only w-button-high-intensity"
            orientation="prev"
          />
          <WButtonPlay
            :highlighted="false"
            :content="content"
            class="w-button-high-intensity"
            :props="{
              large: true,
              icon: true,
            }"
          />
          <WButtonIndex
            v-if="!isLive"
            class="hidden-xs-only w-button-high-intensity"
            orientation="next"
          />
          <div
            v-if="!isLive"
            class="w-player-persistent__card__controls__timer caption grey--text"
          >
            <WPlayerTimer type="current" /> / <WPlayerTimer />
          </div>
        </div>
        <v-card
          ripple
          flat
          nuxt
          color="transparent"
          class="w-player-persistent__card__infos"
          @click.stop="handleOpen"
        >
          <v-avatar tile size="62">
            <v-img
              :src="thumbnailImg.cdnSrc"
              :lazy-src="thumbnailImg.lazySrc"
              class="accent lighten-2"
              aspect-ratio="1"
              cover
            >
              <v-layout
                slot="placeholder"
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular indeterminate color="accent lighten-5" />
              </v-layout>
            </v-img>
          </v-avatar>
          <div class="w-player-persistent__card__infos__text">
            <div class="body-2">
              {{ content.title }}
            </div>
            <div class="caption">
              {{ episodeTitle }}
            </div>
          </div>
          <div class="w-player-persistent__card__infos__actions">
            <v-badge v-if="reactionLikes > 0" color="red" overlap>
              <template v-slot:badge>
                {{ reactionLikes }}
              </template>
              <WButtonLike
                :is-reacted="isReacted"
                :size="24"
                class="w-button-high-intensity"
                @react="handleReact"
                @unreact="handleUnreact"
              />
            </v-badge>
            <WButtonLike
              v-else
              :is-reacted="isReacted"
              :size="32"
              class="w-button-high-intensity"
              @react="handleReact"
              @unreact="handleUnreact"
            />
            <v-badge
              v-if="hasCommentsSystem && reactionComments > 0"
              color="primary"
              overlap
            >
              <template v-slot:badge>
                {{ reactionComments }}
              </template>
              <WComments
                v-if="hasCommentsSystem"
                class="w-button-high-intensity"
              />
            </v-badge>
            <WComments
              v-else-if="hasCommentsSystem"
              class="w-button-high-intensity"
            />
          </div>
        </v-card>
        <div
          class="w-player-persistent__card__controls w-player-persistent__card__controls--last"
        >
          <WVolumeController />
          <v-btn
            v-if="
              isPageContent === false &&
              $voicer.getConfig('disablePageListen') === false
            "
            icon
            color="transparent"
            @click.stop="handleOpen"
          >
            <v-icon
              :color="
                $voicer
                  .conditioner()
                  .ifLight('grey darken-2')
                  .ifDark('white')
                  .render()
              "
            >
              arrow_drop_up
            </v-icon>
          </v-btn>
          <v-btn
            v-else-if="isPageContent === true"
            icon
            color="transparent"
            @click.stop="handleClose"
          >
            <v-icon
              :color="
                $voicer
                  .conditioner()
                  .ifLight('grey darken-2')
                  .ifDark('white')
                  .render()
              "
            >
              close
            </v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import WVolumeController from './WVolumeController'
import { $caption, $data, $metadata } from '~/utils/spoke'
import WButtonPlay from '~/components/WButtonPlay'
import WButtonIndex from '~/components/WButtonIndex'
import WButtonLike from '~/components/WButtonLike'
import WComments from '~/components/WComments'
import WPlayerProgress from '~/components/WPlayerProgress'
import WPlayerTimer from '~/components/WPlayerTimer'

export default {
  components: {
    WButtonPlay,
    WButtonIndex,
    WButtonLike,
    WComments,
    WPlayerProgress,
    WPlayerTimer,
    WVolumeController,
  },

  computed: {
    ...mapGetters({
      hasCommentsSystem: 'player/hasCommentsSystem',
      isPersistantVisible: 'player/isPersistantVisible',
      isAuthenticated: 'auth/isAuthenticated',
      isVisible: 'player/isPersistantVisible',
      content: 'player/content',
      currentEpisode: 'player/currentEpisode',
    }),
    isActive() {
      return this.isAuthenticated && this.isVisible
    },
    isPageContent() {
      return this.$route.name === 'listen-content'
    },
    isReacted() {
      return $data(this.content, 'reactions.isReacted')
    },
    hasComponentBottomNav() {
      return this.$store.state.root.hasComponentBottomNav
    },
    thumbnailImg() {
      const thumbnail = $metadata(this.content, 'thumbnail')

      return thumbnail
        ? $caption(this.content, 'thumbnail')
        : $caption(this.content, 'cards:first')
    },

    title() {
      return $data(this.content, 'title')
    },
    episodeTitle() {
      const isLive = $data(this.content, 'type') === 'live'
      const defaultValue = isLive ? this.$t(`live`) : this.$t('first_episode')

      return $metadata(this.currentEpisode, 'title', defaultValue)
    },
    isLive() {
      return this.content.type === 'live'
    },
    reactionLikes() {
      return $data(this.content, 'reactions.like')
    },
    reactionComments() {
      return $data(this.content, 'messager.stats.comments', 0)
    },
  },

  methods: {
    ...mapActions({
      react: 'contents/react',
      unreact: 'contents/unreact',
    }),

    handleClose(e) {
      this.$router.go(-1)
    },

    handleOpen(e) {
      if (this.$voicer.getConfig('disablePageListen', false) === false) {
        this.$router.push({
          path: `/listen/${this.$store.state.player.content.id}`,
          query: {
            episodeIndex: this.$store.state.player.episodeIndex,
          },
        })
      }
    },

    handleReact() {
      this.react({
        content: this.content,
      })
    },

    handleUnreact() {
      this.unreact({
        content: this.content,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.w-player-persistent {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;

  .text-is-live {
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 5px;
    margin-left: 4px;
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 14px;

    &__controls,
    &__infos {
      min-width: 33.334%;
      height: 100%;
      display: flex;
      align-content: center;

      & > * {
        align-self: center;
      }

      &--last {
        margin-right: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &__timer {
        display: flex;
      }
    }

    &__infos {
      cursor: pointer;
      min-width: 380px;
      max-width: 450px;

      .v-avatar {
        align-self: center;
        margin-right: 8px;
      }

      &__text {
        align-self: center;
        flex: 1;
        flex-grow: 1;
      }

      &__actions {
        flex-shrink: 1;
        padding-right: 16px;
      }
    }
  }
}

.w-button-high-intensity {
  z-index: 10;
}
</style>
