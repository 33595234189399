<i18n>
{
  "en": {
    "live": "Live events"
  },
  "fr": {
    "live": "Evènements en direct"
  }
}
</i18n>

<template>
  <div v-if="isLoading === false && list > 0" class="w-wavies pa-1 mb-3">
    <div class="caption grey--text text--lighten-1 mb-2">
      {{ $t('live') }}
    </div>
    <v-tabs
      v-model="tabIndex"
      class="w-wavies__tabs"
      color="transparent"
      height="92"
      :loading="isLoading"
    >
      <WWavy
        v-for="(item, i) in list"
        :key="item.__uid__"
        :content="item"
        @select="(_) => handleSelect(i)"
      />
    </v-tabs>
  </div>
</template>

<script>
import WWavy from './WWavy'

export default {
  components: {
    WWavy,
  },
  data: () => ({
    isLoading: true,
    list: [],
    tabIndex: 0,
  }),
  mounted() {
    this.$spoke
      .collection('contents')
      .get({
        is: 'live',
      })
      .on('success', (docs) => {
        this.list = docs.toArray()
      })
  },
  methods: {
    handleSelect(index) {
      this.tabIndex = index
    },
  },
}
</script>

<style lang="scss">
.w-wavies {
  &__tabs {
    &__item {
      min-width: 210px;
      max-width: 225px;

      &__content {
        padding-left: 10px;
        text-align: left;
      }
    }
  }

  &__item__content__play {
    text-transform: initial;
  }

  .v-tabs__container {
    flex-wrap: wrap;
  }
}
</style>
