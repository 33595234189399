<template>
  <v-layout column>
    <v-fade-transition group>
      <template v-for="response in responses">
        <WListItem :key="response.__uid__" :item="response" auto-reduce />
      </template>
    </v-fade-transition>
  </v-layout>
</template>

<script>
import WListItem from '../WCommentsThreads/WListItem'
export default {
  components: {
    WListItem,
  },
  props: {
    responses: {
      type: Array,
      required: true,
    },
  },
}
</script>
