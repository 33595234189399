<template>
  <v-app :dark="$voicer.isThemeDark()" class="w-app w-app--minimal">
    <WScreenLoader />
    <nuxt />
    <w-alert />
    <w-ath-safari />
    <w-rgpd-modal />
  </v-app>
</template>

<script>
import WAlert from '~/components/WAlert'
import WAthSafari from '~/components/WAthSafari'
import WRgpdModal from '~/components/WRGPDModal'
import WScreenLoader from '~/components/WScreenLoader'

import ExcentricMinimalLayoutMixin from '~/excentrics/mixins/layouts/minimal'

export default {
  components: {
    WAlert,
    WAthSafari,
    WRgpdModal,
    WScreenLoader,
  },

  mounted() {
    ExcentricMinimalLayoutMixin.mounted.call(this)
  },
}
</script>
