<i18n>
{
  "en": {
    "on": "on",
    "chapters": "chapters",
    "like": "Like",
    "listen": "Listen",
    "stop": "Stop",
    "error": "Error",
    "comment": "Comment",
    "comments": "No comments | One comment | {count} comment"
  },
  "fr": {
    "on": "Le",
    "chapters": "chapitres",
    "like": "Favoris",
    "listen": "Écouter",
    "stop": "Arrêter",
    "error": "Erreur",
    "comment": "Commenter",
    "comments": "Aucun commentaire | Un commentaire | {count} commentaires"
  }
}
</i18n>

<template>
  <v-layout justify-center>
    <v-container
      class="w-player pa-0"
      :class="{
        'pb-5': $voicer.isDesktopOrTablet,
      }"
    >
      <WHighlighted :card-options="{ ripple: false }" :content="content" />
      <div>
        <div class="w-player__summary body-2">
          <div
            :class="{ 'mb-5': sumEpisodes > 1 }"
            class="w-playr__summary__text"
            v-html="fulltext"
          />
        </div>
        <WPlayerChapters />
        <WDocuments v-if="hasDocuments" :documents="documents" />
        <WLinks v-if="hasLinks" :links="links" />
      </div>
    </v-container>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'

import contentInfosMixin from '~/mixins/contentInfos'
import WPlayerChapters from '~/components/WPlayerChapters'
import WDocuments from '~/components/WDocuments'
import WLinks from '~/components/WLinks'
import WHighlighted from '~/components/WVoicyCard/WHighlighted'

export default {
  components: {
    WHighlighted,
    WDocuments,
    WLinks,
    WPlayerChapters,
  },

  mixins: [contentInfosMixin],

  computed: {
    containerTextClass() {
      return this.$voicer.getThemeConfigProperty('dark') === true
        ? `grey--text`
        : `white--text`
    },
  },

  methods: {
    ...mapActions({
      handleToggleComments: 'comments/toggleCommentsVisibility',
    }),
    handleClick() {
      this.$store.dispatch('player/setEpisodeIndex')
    },
  },
}
</script>

<style lang="scss" scoped>
.w-player {
  &__heading {
    position: relative;
  }

  &__summary {
    margin-top: 24px;
    text-align: center;
    line-height: 32px;

    &__text {
      white-space: pre-wrap;
      word-break: break-word;
      text-overflow: unset;
      overflow: hidden;
    }
  }
}
</style>
