<i18n>
{
  "en": {
    "connection": "Connection",
    "authentication_failed": "Authentication failed"
  },
  "fr": {
    "connection": "Connexion",
    "authentication_failed": "Impossible de vous authentifier"
  }
}
</i18n>

<template>
  <div id="auth0Lock" ref="auth0" />
</template>

<script>
import Auth0Lock from 'auth0-lock'
import { mapActions, mapState } from 'vuex'
import $merge from 'lodash.merge'

import { getSSOProvider } from '~/utils/voicer'

export default {
  computed: {
    config() {
      return getSSOProvider('WSSOAuth0')[1]
    },
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
    }),
  },

  mounted() {
    if (!this.isAuthenticated) {
      this.lock = new Auth0Lock(
        this.config.clientId,
        this.config.domain,
        $merge(
          {
            container: 'auth0Lock',
            language: this.$i18n.locale,
            languageDictionary: {
              title: this.$t('connection'),
            },
            auth: {
              redirect: false,
            },
            theme: {
              logo: this.$voicer.getConfig('metadatas.iconFileName'),
            },
          },
          this.config.lockOptions
        )
      )

      this.lock.on('authenticated', (authResult) => {
        this.login(authResult)
      })

      this.lock.on('authorization_error', (error) => {
        this.$voicer.captureException(error)
        this.notifyError(this.$t('authentication_failed'))
      })

      this.lock.on('unrecoverable_error', (error) => {
        this.$voicer.captureException(error)
        this.notifyError(this.$t('authentication_failed'))
      })

      try {
        this.$refs.auth0 && this.lock.show()
      } catch (error) {
        // empty
      }
    }
  },

  methods: {
    ...mapActions({
      authenticateAuth0Provider: 'auth/authenticateAuth0Provider',
      notifyError: 'notifier/setError',
    }),
    login(authResult) {
      this.authenticateAuth0Provider(authResult)
    },
  },
}
</script>

<style lang="scss">
.auth0-lock-header {
  display: none !important;
}
.auth0-lock-form {
  padding: 0 16px !important;
}
.auth0-lock-last-login-pane p {
  display: none !important;
}
.auth0-lock-social-button-icon {
  display: none !important;
}
.auth0-lock-social-button-text {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  padding: 0 15px !important;
  text-align: center !important;
  text-transform: uppercase !important;
}
.auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
  height: auto !important;
}
.auth0-lock.auth0-lock.auth0-lock-opened-in-frame {
  z-index: 0;
}

.auth0-lock.auth0-lock .auth0-lock-cred-pane {
  background: transparent !important;
}

.auth0-lock-iphone.auth0-lock .auth0-lock-content {
  padding: 0px 16px !important;
}

.auth0-lock-iphone.auth0-lock .auth0-lock-form {
  display: block;
  animation: none !important;
  padding: 0 !important;
}
</style>
