var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bb-card-holder fill-height",class:{
    'pa-4': _vm.$voicer.isMobile === false,
    'pa-2': _vm.$voicer.isMobile === true,
  }},[_c('v-card',{class:{
      'bb-card': true,
      'bb-card--selected': _vm.isSelectedContent,
      'bb-card--ie': _vm.$voicer.isIE,
    },attrs:{"ripple":"","color":_vm.getColor,"dark":"","flat":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectWavy($event)}}},[_c('v-switch',{attrs:{"value":"","input-value":_vm.isSelectedContent,"color":"primary"}}),_vm._v(" "),_c('div',{staticClass:"bb-card__title title"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }