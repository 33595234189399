<i18n>
{
  "en": {
    "software_versions": "Software verisions",
    "interface_version": "Interface version",
    "spoke_version": "Spoke version",
    "close": "Close",
    "app_info": "About",
    "logout": "Logout",
    "password_reseted": "Password has been reset",
    "settings": "Settings",
    "versions": "Versions"
  },
  "fr": {
    "software_versions": "Versions logicielles",
    "interface_version": "Version de l'interface",
    "spoke_version": "Version de spoke",
    "close": "Fermer",
    "app_info": "À propos",
    "logout": "Se déconnecter",
    "settings": "Paramètres",
    "versions": "Versions"
  }
}
</i18n>

<template>
  <v-navigation-drawer
    v-model="visible"
    fixed
    floating
    right
    temporary
    hide-overlay
    :style="$voicer.getThemeConfigProperty('components.aside.style')"
    v-bind="$voicer.getThemeConfigProperty('components.aside.props')"
  >
    <v-toolbar flat class="transparent">
      <v-list class="pa-0">
        <v-list-tile avatar>
          <v-list-tile-avatar tile>
            <w-logo />
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title v-text="$metadatas.name" />
            <v-list-tile-sub-title
              v-if="$metadatas.desc"
              v-text="$metadatas.desc"
            />
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-toolbar>
    <v-divider />
    <div
      v-if="
        $voicer.isOpenSite() ||
        $voicer.hasLocalAuthModeUniquePassword() === false
      "
    >
      <v-toolbar flat class="transparent">
        <v-list class="pa-0">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <!-- <img src="https://randomuser.me/api/portraits/men/85.jpg"> -->
              <v-avatar color="primary" size="40">{{ userChars }}</v-avatar>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>{{ name }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ email }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-divider />
    </div>

    <v-list>
      <v-list-group
        v-for="navigation in navs"
        :key="navigation.title"
        v-model="navigation.active"
        :prepend-icon="navigation.icon"
        :append-icon="navigation.items ? 'expand_more' : ''"
        no-action
        @click="handleClickMenu(navigation)"
      >
        <v-list-tile slot="activator">
          <v-list-tile-content>
            <v-list-tile-title v-text="navigation.title" />
          </v-list-tile-content>
        </v-list-tile>

        <div v-if="navigation.items">
          <v-list-tile
            v-for="subNavigation in navigation.items"
            :key="subNavigation.title"
            @click="handleClickMenu(subNavigation)"
          >
            <v-list-tile-content>
              <v-list-tile-title v-text="subNavigation.title" />
            </v-list-tile-content>

            <v-list-tile-action>
              <v-icon>{{ subNavigation.action }}</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </div>
      </v-list-group>
    </v-list>
    <v-dialog v-model="dialogVersions" max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ $t('software_versions') }}
        </v-card-title>
        <v-card-text>
          {{ $t('interface_version') }} {{ coreVersion }}<br />
          {{ $t('spoke_version') }} {{ spokeVersion }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="dialogVersions = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import pkg from '~/package.json'
import spoke from '~/utils/spoke'

import utilsMixin from '~/mixins/utils'

export default {
  mixins: [utilsMixin],
  props: {
    navigations: {
      type: Array,
      default: () => [],
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogVersions: false,
  }),
  computed: {
    coreVersion() {
      return pkg.version
    },
    visible: {
      get() {
        return this.open
      },
      set(state) {
        this.open = state
      },
    },
    navs() {
      return this.navigations.map((nav) => {
        if (nav.items && Array.isArray(nav.items)) {
          nav.items = nav.items.map((snav) => {
            return {
              ...snav,
              title:
                typeof snav.title === 'function'
                  ? snav.title(this)
                  : snav.title,
            }
          })
        }

        return {
          ...nav,
          title: typeof nav.title === 'function' ? nav.title(this) : nav.title,
        }
      })
    },
    spokeVersion() {
      return spoke.version
    },
  },
  watch: {
    open(state) {
      if (state === false) {
        this.$emit('close-drawer')
      }
    },
  },
  methods: {
    handleClickMenu(item) {
      if (!item.disableAutoClose) {
        this.$emit('close-drawer')
      }
      if (item.noAction) {
        return
      }

      if (typeof item.onClick === 'function') {
        item.onClick(this)
      }

      // default type is link
      if (!item.type || item.type === 'link') {
        this.$router.push({
          path: item.value,
        })
      }
    },
  },
}
</script>
