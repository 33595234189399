<i18n>
{
  "en": {
    "answer": "Answer to {name}"
  },
  "fr": {
    "answer": "En réponse à {name}"
  }
}
</i18n>

<template>
  <v-layout fill-height column class="w-comments-responses pa-1">
    <div class="ma-3 w-comments-responses--main">
      <div class="w-comments-responses--title grey--text caption mb-1">
        <span
          >{{ userName }} · {{ formatDateHour(currentThread.createdAt) }}</span
        >
      </div>
      <v-card flat>
        <v-card-text>
          <WItemSummary :summary="currentThread.content" />
        </v-card-text>
      </v-card>
    </div>
    <div v-if="currentThreadResponses.length">
      <v-divider />
      <div class="body-1 ma-3">
        {{ $t('answer', { name: userName }) }}
      </div>
      <WList :responses="currentThreadResponses" />
    </div>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import WItemSummary from '../WCommentsThreads/WItemSummary'
import WList from './WList'
import formatDate from '~/mixins/formatDate'
import { $metadata } from '~/utils/spoke'

export default {
  components: {
    WItemSummary,
    WList,
  },

  mixins: [formatDate],

  computed: {
    ...mapGetters({
      currentThread: 'comments/currentThread',
      currentThreadResponses: 'comments/currentThreadResponses',
    }),
    userName() {
      return $metadata(this.currentThread.poster, 'name', 'Anonyme')
    },
  },
}
</script>

<style lang="scss">
.w-comments-responses {
  &--title {
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    justify-content: space-between;
  }

  &--main {
    .v-card__text {
      padding: 0;
    }
  }
}
</style>
