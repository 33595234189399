import { render, staticRenderFns } from "./BbCard.vue?vue&type=template&id=30e679a4&"
import script from "./BbCard.vue?vue&type=script&lang=js&"
export * from "./BbCard.vue?vue&type=script&lang=js&"
import style0 from "./BbCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VSwitch})
