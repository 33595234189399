<template>
  <v-container
    fluid
    class="page--index pa-0"
    :class="{
      'page--index--desktop': $voicer.isDesktopOrTablet,
      'page--index--mobile': $voicer.isMobile,
    }"
  >
    <div class="blocks-container">
      <w-block name="radio-heading" />
      <BbBlockHeading />
    </div>
    <v-sheet
      :color="$voicer.getShellConfig('featuredSectionColor')"
      tile
      class="featured-container"
    >
      <v-container
        grid-list-md
        :class="{
          'pa-0': $voicer.isDesktopOrTablet,
          'pb-2': true,
        }"
      >
        <v-layout row wrap>
          <v-flex v-for="item in featuredItems" :key="item.id" xs12 sm6>
            <BbBigCard :content="item" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-sheet
      :color="$voicer.getShellConfig('streamsSectionColor')"
      tile
      class="streams-container"
    >
      <v-container grid-list-md class="mt-0 mb-0 pa-0">
        <v-layout row wrap class="pa-0 ma-0 fill-height">
          <v-flex v-for="item in items" :key="item.id" xs6 sm3 class="pa-0">
            <BbCard :content="item" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-sheet>
    <div class="fake-desktop-container"></div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import BbBigCard from './components/BbBigCard'
import BbCard from './components/BbCard'
import { getExcentricComponent, getShellConfig } from '~/utils/voicer'
import PageHead from '~/mixins/pageHead'

function sortFromPosition(a, b) {
  if (a.metadatas.position > b.metadatas.position) {
    return 1
  } else if (a.metadatas.position < b.metadatas.position) {
    return -1
  }
  return 0
}

export default {
  components: {
    BbBigCard,
    BbCard,
    BbBlockHeading() {
      return getExcentricComponent('bb-multiradios-heading', () =>
        getShellConfig('blocks.radiosHeading')
      )
    },
  },
  mixins: [PageHead],
  computed: {
    ...mapGetters({
      contents: 'contents/contents',
    }),
    featuredItems() {
      return this.contents
        .filter((content) => content.isFeatured)
        .sort(sortFromPosition)
    },
    items() {
      return this.contents
        .filter((content) => content.isFeatured === false)
        .sort(sortFromPosition)
    },
  },
  mounted() {
    const page = this.$voicer.findPageFromSlug('default')

    this.$store.dispatch('contents/getContents', page)
  },
}
</script>

<style lang="scss">
.page--index {
  display: flex;
  flex-direction: column;

  &--desktop {
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 72px;
    overflow: auto;
  }

  &--mobile {
    .streams-container {
      max-height: auto !important;
      height: auto !important;
    }
  }

  .blocks-container {
    width: 100%;
    max-width: 100%;
    order: 0;
    flex: 1 1 auto;
  }

  .featured-container {
    width: 100%;
    order: 0;
    flex: 1 1 auto;
    flex-basis: inherit;
    display: flex;

    .v-tabs__container {
      height: auto;
      .v-tabs__item {
        display: block;
      }
    }
  }

  .streams-container {
    width: 100%;
    height: 92px;
    align-self: auto;
    display: flex;

    .v-card {
      height: 42px;
    }
  }
}
</style>
