<i18n>
{
  "en": {
    "comments": "Comments"
  },
  "fr": {
    "comments": "Commentaires"
  }
}
</i18n>

<template>
  <v-card class="w-modal">
    <v-toolbar card dense>
      <v-btn v-if="parentId" icon small @click.native="handleBack">
        <v-icon small> arrow_back </v-icon>
      </v-btn>
      <div class="subheading">
        <span v-if="!parentId">
          {{ $t('comments') }}
        </span>
        <span v-else>
          {{ currentThread.user.name }}
        </span>
      </div>
      <v-spacer />
      <v-btn icon small @click.native="handleClose">
        <v-icon small> close </v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <!-- Modal toolbar ends -->

    <!-- Modal scrollable content with threads and responses -->
    <v-card-text ref="scrollableArea" class="w-modal__content pa-0">
      <WLoader v-if="!parentId && isLoading" />
      <transition v-if="!parentId" name="switch-right">
        <WCommentsThreads :loading="isLoading" />
      </transition>
      <transition v-else name="switch-left">
        <WCommentsResponses />
      </transition>
    </v-card-text>
    <v-divider />
    <!-- Modal scrollable content ends -->

    <!-- Modal bottom form action -->
    <v-card-actions class="w-modal__actions">
      <WCommentsPost :parent-id="parentId" :current-thread="currentThread" />
    </v-card-actions>
    <!-- Modal bottom ends -->
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import WCommentsThreads from '~/components/WCommentsThreads'
import WCommentsResponses from '~/components/WCommentsResponses'
import WCommentsPost from '~/components/WCommentsPost'
import WLoader from '~/components/WCommentsThreads/WLoader'

export default {
  components: {
    WCommentsThreads,
    WCommentsResponses,
    WCommentsPost,
    WLoader,
  },

  computed: {
    ...mapGetters({
      isLoading: 'comments/isLoading',
      parentId: 'comments/parentId',
      threads: 'comments/threads',
      currentThread: 'comments/currentThread',
    }),
  },

  watch: {
    /**
     * Everytime a new message is posted we scroll to :
     * top if we're viewing the threads list
     * bottom if we're viewing the responses in a thread
     */
    threads() {
      this.$nextTick(() => {
        const scrollableArea = this.$refs.scrollableArea

        if (this.parentId) {
          scrollableArea.scrollTop = 0
        } else {
          scrollableArea.scrollTop = scrollableArea.scrollHeight
        }
      })
    },
  },

  methods: {
    ...mapActions({
      toggleCommentType: 'comments/toggleCommentType',
    }),

    /**
     * Close the modal
     */
    handleClose() {
      this.$emit('handleClose')
    },

    /**
     * switch from threads to responses
     */
    handleBack() {
      this.toggleCommentType()
    },
  },
}
</script>

<style lang="scss">
.w-modal {
  &__content {
    height: 304px; // Display 4 messages and respect MDS
  }

  .switch-right-enter-active,
  .switch-right-leave-active,
  .switch-left-enter-active,
  .switch-left-leave-active {
    transition: all 0.3s ease-in-out;
  }
  .switch-right-enter,
  .switch-left-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }
  .switch-left-enter,
  .switch-right-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {
  .w-modal {
    &__content {
      height: 100%; // Takes full height of screen
    }
  }
}
</style>
