<template>
  <v-card
    ripple
    flat
    :class="{
      'bb-big-card fill-height pa-4': true,
      'bb-big-card--mobile': $voicer.isMobile,
      'bb-big-card--selected': isSelectedContent,
    }"
    :color="cardBackgroundColor"
    light
    @click.stop="handleSelectWavy"
  >
    <div class="bb-big-card__picture">
      <img :src="cardImg.originSrc" :height="height" />
    </div>
    <div class="bb-big-card__metas pa-2 mt-2">
      <div v-if="titleImage">
        <img :src="titleImage" />
      </div>
      <div v-else>
        <div class="title" v-text="title" />
        <div class="body-2 mt-2" v-text="summary" />
      </div>
    </div>
  </v-card>
</template>

<script>
import contentInfosMixin from '~/mixins/contentInfos'
import mediaMixin from '~/mixins/media'
import { $data, $metadata } from '~/utils/spoke'

export default {
  mixins: [contentInfosMixin, mediaMixin],
  props: {
    height: {
      type: Number,
      default: () => 200,
    },
    imageProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    titleImage() {
      const img = $metadata(this.content, 'img_title', null)

      if (img) {
        return $data(img, 'medias[0].value', null)
      }

      return null
    },
    cardBackgroundColor() {
      return this.$voicer.getShellConfig('featuredCardsColor[0]', 'transparent')
    },
  },
}
</script>

<style lang="scss">
.bb-big-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 2px transparent solid;

  .bb-big-card__picture {
    position: relative;
    height: auto;

    img {
      max-height: 130px;
    }
  }

  &--mobile {
    .bb-big-card__picture {
      img {
        max-height: 150px;
      }
    }
  }

  &--selected {
    border: 2px white solid !important;
  }

  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &__metas {
    text-align: left;
    height: 120px;
  }
}
</style>
