<template>
  <v-container class="page--episodes" grid-list-md>
    <w-block name="heading" />
    <v-layout row wrap>
      <w-wavies />
      <v-flex xs12>
        <w-filters v-if="pageOptions.filter" />
      </v-flex>
      <w-voicy :options="pageOptions" :load-more="handleLoadMoreContents" />
    </v-layout>
  </v-container>
</template>

<script>
import PageMixin from '~/mixins/page'

export default {
  name: 'Home',
  transition: 'page',
  mixins: [PageMixin],
  mounted() {
    const page = this.$voicer.findPageFromSlug('episodes')

    return this.$store.dispatch('contents/getContents', page)
  },
}
</script>
