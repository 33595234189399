<i18n>
{
  "en": {
    "new_pwd": "New password",
    "new_pwd_hint": "Input your new password",
    "new_pwd_action": "Confirm",
    "foorgot_pwd": "Forgot password ?",
    "forgot_pwd_hint": "Don't panic ! Fill the form and we will send you the necessary info to create a new password. Check your email.",
    "forgot_pwd_action": "Renew password",
    "forgot_pwd_sent": "A link was sent to your emil to reset your password.",
    "forgot_pwd_result": "Mail sent",
    "cancel": "Cancel"
  },
  "fr": {
    "new_pwd": "Nouveau mot de passe",
    "new_pwd_hint": "Indiquez ci-après le nouveau mot de passe avec lequel vous souhaitez protéger votre compte.",
    "new_pwd_action": "Confirmer",
    "forgot_pwd": "Mot de passe oublié ?",
    "forgot_pwd_hint": "Pas de panique ! Remplissez le formulaire ci-dessous et nous vous enverrons par email les informations pour créer un nouveau mot de passe. Consultez votre messagerie.",
    "forgot_pwd_action": "Retrouver mon mot de passe",
    "forgot_pwd_sent": "Un lien vous a été envoyé par mail afin que vous puissiez réinitialiser votre mot de passe.",
    "forgot_pwd_result": "Mail envoyé",
    "cancel": "Annuler"
  }
}
</i18n>

<template>
  <w-layout-modal
    :class="{
      'w-page-password_reset_confirmation--mobile':
        $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    class="w-page-password_reset_confirmation"
  >
    <template v-slot:heading>
      <div class="heading-metadatas-container">
        <w-logo width="24" />
        <h1 class="title ml-1">
          {{ $metadatas.appName }}
        </h1>
      </div>
      <div>
        <v-btn
          depressed
          nut
          to="/?utm_source=password_reset"
          color="transparent"
        >
          {{ $t('cancel') }}
        </v-btn>
      </div>
    </template>
    <template v-slot:content>
      <WFormResetPassword
        :sso-providers="ssoProviders"
        @submit="handleSubmit"
      />
    </template>
    <template v-slot:baseline>
      <nuxt-link to="/data/terms-of-service">
        {{ $t('terms_usage_policy') }}
      </nuxt-link>
      <nuxt-link to="/data/legal-notice">
        {{ $t('legal_notice') }}
      </nuxt-link>
      <nuxt-link to="/data/personal-data">
        {{ $t('confidentiality_policy') }}
      </nuxt-link>
    </template>
  </w-layout-modal>
</template>

<script>
import { getSSOProviders } from '~/utils/voicer'

import WFormResetPassword from '~/components/WFormResetPassword'

export default {
  name: 'PasswordResetIndex',
  layout: 'minimal',
  components: {
    WFormResetPassword,
  },

  data: () => ({
    isLoading: false,
  }),

  computed: {
    ssoProviders() {
      return getSSOProviders()
    },
  },

  mounted() {
    if (this.$route.query.userId && this.$route.query.code) {
      this.hasReceivedMail = true
    }
  },

  methods: {
    /**
     * Launch the ask password action
     */
    async handleSubmit(payload) {
      // query
      this.isLoading = true
      try {
        await this.$store.dispatch('auth/askNewPassword', payload.login)
        this.$router.push('/password_reset/confirmation')
      } catch (error) {
        // catched in store
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
.w-page-password_reset_confirmation {
  .w-layout-modal__heading {
    justify-content: space-between;

    .heading-metadatas-container {
      display: flex;
      align-items: center;
    }
  }
}
</style>
