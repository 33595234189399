<template>
  <v-container class="w-empty pa-0">
    <v-flex xs12>
      <slot name="before" />
    </v-flex>
    <v-layout align-center fill-height justify-center>
      <v-flex class="w-empty__container" xs12 align-self-center>
        <UndrawEmpty :height="height" />
        <div class="w-empty__content">
          <slot />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UndrawEmpty from 'vue-undraw/UndrawEmpty.vue'

export default {
  components: {
    UndrawEmpty,
  },
  props: {
    height: {
      type: String,
      default: '200px',
    },
  },
}
</script>

<style lang="scss">
.w-empty {
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__content {
    margin-top: 24px;
  }
  p {
    margin-top: 8px;
    font-size: 16px !important;
  }
}
</style>
