import { render, staticRenderFns } from "./WInputLogin.vue?vue&type=template&id=5bfe57f4&"
import script from "./WInputLogin.vue?vue&type=script&lang=js&"
export * from "./WInputLogin.vue?vue&type=script&lang=js&"
import style0 from "./WInputLogin.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./WInputLogin.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})
