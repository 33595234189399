<i18n>
  {
    "en": {
      "terms": "Terms of usage",
      "legals": "Legal Notices",
      "confidentiality": "Confidentiality policy",
      "wait": "Please wait for authentication process",
      "fail_error": "Connection failed - Error while authentication",
      "fail_missing_code": "Connection failed - missing information"
    },
    "fr": {
      "terms": "Conditions d'utilisation",
      "legals": "Mentions Légales",
      "confidentiality": "Politique de confidentialité",
      "wait": "Authentification en cours",
      "fail_error": "Connexion impossible - échec de la récupération du compte",
      "fail_missing_code": "Connexion impossible - informations manquantes"
    }
  }
</i18n>

<template>
  <w-layout-modal
    :loading="isLoading"
    :class="{
      'w-page-redirect--mobile': $vuetify.breakpoint.smAndDown,
    }"
    :props="{
      flat: true,
      tile: true,
    }"
    class="w-page-redirect"
  >
    <template v-slot:content>{{ $t('wait') }}</template>
    <template v-slot:baseline>
      <nuxt-link to="/data/terms-of-service">{{ $t('terms') }}</nuxt-link>
      <nuxt-link to="/data/legal-notice">{{ $t('legals') }}</nuxt-link>
      <nuxt-link to="/data/personal-data">{{
        $t('confidentiality')
      }}</nuxt-link>
    </template>
  </w-layout-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  layout: 'minimal',

  name: 'LoginRedirect',

  data: () => ({
    isLoading: false,
  }),

  computed: {
    ...mapGetters({
      siteName: 'root/siteName',
    }),
  },

  async mounted() {
    if (this.$route.query.code) {
      try {
        this.isLoading = true
        await this.authenticateKeycloakProvider({
          code: this.$route.query.code,
        })
      } catch (e) {
        this.notifyError(this.$t('fail_error'))
        this.$router.replace('/login')
      } finally {
        this.isLoading = false
      }
    } else {
      this.notifyError(this.$t('fail_missing_code'))
      this.$router.replace('/login')
    }
  },

  methods: {
    ...mapActions({
      authenticateKeycloakProvider: 'auth/authenticateKeycloakProvider',
      notifyError: 'notifier/setError',
    }),
  },
}
</script>

<style lang="scss">
.w-page-redirect {
  .flexcard {
    border: 1px #ddd solid;
  }

  &--mobile {
    .flexcard {
      border: 0;
      border-top: 1px #ddd solid;
    }
  }
}
</style>
