<template>
  <div class="fill-height">
    <WList v-if="threads.length > 0" :threads="threads" />
    <WEmpty v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import WList from './WList'
import WEmpty from './WEmpty'

export default {
  components: {
    WList,
    WEmpty,
  },

  computed: mapGetters({
    threads: 'comments/threads',
  }),
}
</script>
